.fc .fc-daygrid-day.fc-day-today{
    background-color: #1a252f !important;
}

.fc-view-harness {
    background-color: #2c3e50 !important;
}

 span.chakra-switch__track[data-checked] {
   background:  #319795;
}

.fc-theme-standard .fc-list-day-cushion {
   background-color: #192d42 !important;
}