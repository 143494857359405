.image-gallery-content .image-gallery-slide .image-gallery-image {
  height: 100%;
}
.image-gallery-slide.center {
  position: relative;
  height: 360px;
}

.customImageGallery .image-gallery-slide.center {
  position: relative;
  height: 200px;
}