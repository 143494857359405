.image-picker-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.image-back {
  position: relative;
}
.image-back .plus-icon {
  font-size: 24px;
  position: absolute;
  cursor: pointer;
  right: 5px;
  bottom: -4px;
}

.picked-image {
  width: 200px;
  height: 200px;
  border: 2px solid #aeaeae;
  border-radius: 50%;
}
.text {
  font-size: 12px;
  color: #fff;
  text-align: center;
  width: 80px;
}
.image-picker {
  /* background: url(${lekoIcon}); */
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.image-picker .plus-icon {
  font-size: 24px;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
